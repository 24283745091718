import { Controller } from '@hotwired/stimulus'

const namePrefixLength = 6;

function extractName(element) {
  return element.getAttribute("aria-controls").substr(namePrefixLength);
}

export default class extends Controller {
  static values = {
    index: String // We'll use this to drive which tab is visible
  }
  static targets = [
    'tab',
    'panel',
  ]

  connect() {
    this.setActiveTab()
  }

  setActiveTab() {
    this.tabTargets.forEach(el => {
      el.setAttribute("aria-selected", extractName(el) === this.indexValue)
    })
    this.panelTargets.forEach(el => {
      el.hidden = el.id.substr(namePrefixLength) !== this.indexValue
    })
  }

  activateTab(event) {
    event.preventDefault()

    this.indexValue = extractName(event.target)
    this.setActiveTab()
  }
}
