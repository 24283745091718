import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (this.element.tagName === "A" && this.element.href !== undefined) {
      this.element.href = "#triggerZohoChat";
    }
  }

  activate(ev) {
    ev.preventDefault();

    try {
      let state = $zoho.salesiq.floatwindow.visible();
      let new_state = state === 'show' ? 'hide' : 'show';
      $zoho.salesiq.floatwindow.visible(new_state);
    }
    catch(error) {
      this.dispatch("activated")
    }
  };
}
