import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"
import { Turbo } from "@hotwired/turbo-rails";

enum Loading {
  Wait = "wait",
  Load = "load",
  Reload = "reload"
}

export default class extends Controller {
  static targets = ["passwordInput"];
  static values = {
    "loading": {
      type: String,
      default: "wait"
    },
    "url": String
  }
  static debounces = ["update"]

  declare readonly passwordInputTarget: HTMLInputElement
  declare readonly urlValue: URL
  declare csrfToken: string
  declare loadingValue: Loading

  connect() {
    this.csrfToken = document.querySelector('meta[name="csrf-token"]')?.content;

    useDebounce(this)
  }

  loadingValueChanged(value: string) {
    if (value == Loading.Load) {
      this.computeScore()
    }
  }

  update() {
    this.updateLoadingValue()
  }

  updateLoadingValue(isUserAction: Boolean = false) {
    if (this.loadingValue == Loading.Wait || this.loadingValue == Loading.Reload) {
      this.loadingValue = Loading.Load
    } else if (this.loadingValue == Loading.Load && isUserAction) {
      this.loadingValue = Loading.Reload
    } else {
      this.loadingValue = Loading.Wait
    }
  }

  computeScore() {
    const options: RequestInit = {
      method: "POST",
      headers: {
        "Accepts": "text/vnd.turbo-stream.html",
        "Content-type": "application/json",
        'X-CSRF-Token': this.csrfToken
      },
      body: JSON.stringify({ password: this.passwordInputTarget.value })
    }

    fetch(this.urlValue, options)
      .then(response => {
        if (!response.ok) { throw new Error("Network response was not ok") }

        return response.text()
      })
      .then(html => {
        Turbo.renderStreamMessage(html)
        this.updateLoadingValue(false)
      })
      .catch(error => console.log(error));
  }
}
