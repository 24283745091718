import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    timestamp: String
  }

  ack() {
    this.element.remove()

    Rails.ajax({
      url: "/announcement",
      type: "PUT",
      data: { timestamp: this.element.getAttribute("data-timestamp") }
    })
  }
}
