import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    document.querySelectorAll(".pricelist > li > div > a").forEach(el => {
      let a = document.createElement("a");
      let li = document.createElement("li")

      a.href = `#${el.id}`
      a.text = el.text
      a.dataset.turbo = false

      li.append(a)
      this.element.append(li)
    });
    this.element.hidden = false
  }
}
