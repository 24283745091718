import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["trigger"]

  static values = {
    customer: { type: Object, default: {} },
    delay: { type: Number, default: 10 },
    timeout: { type: Number, default: 15 },
    state: { type: String, default: 'inactive' },
    trigger: { type: Boolean, default: false },
    widgetcode: String
  }

  connect() {
    this.delayValue == 0 ? this.loadScript() : this.initDelayTimeout()
  }

  disconnect() {
    this.clearDelayTimeout();
  }

  // TODO: add custom floating bubble and hide once real one is loaded.

  get script() {
    let script = document.createElement("script")
    script.type = "text/javascript";
    script.id = "zsiqscript";
    script.defer = true;
    script.src = "https://salesiq.zoho.com/widget";

    return script;
  }

  get $zoho() {
    window.$zoho ||= {
      salesiq: {
        widgetcode: this.widgetcodeValue,
        values: this.customerValue,
        ready: this.ready.bind(this)
      }
    }

    return window.$zoho
  }

  get $salesiq() {
    return this.$zoho.salesiq
  }

  loadScript() {
    if (["loading", "active", "failed"].includes(this.stateValue)) { return }

    this.stateValue = 'loading'
    this.$zoho // setup window.$zoho for their script tag

    this.element.append(this.script)
    this.initDelayTimeout();
  }

  initLoadTimeout() {
    this.loadTimeout = setTimeout(() => { this.stateValue = "failed" }, this.timeoutValue)
  }

  clearLoadTimeout() {
    if (this.loadTimeout) { clearTimeout(this.loadTimeout) }
  }

  ready() {
    this.clearLoadTimeout()
    this.$salesiq.tracking.forcesecure(true)
    this.stateValue = 'active'

    if (this.triggerValue) {
      this.triggerChat()
    }
  }

  get delay() {
    return this.delayValue * 1000;
  }

  initDelayTimeout() {
    this.delayTimeout = setTimeout(() => { this.loadScript() }, this.delay)
  }

  clearDelayTimeout() {
    if (this.delayTimeout) { clearTimeout(this.delayTimeout) }
  }

  trigger() {
    this.triggerValue = true
    this.loadScript()
  }

  triggerChat() {
    try {
      let state = this.$salesiq.floatwindow.visible()
      let new_state = state === 'show' ? 'hide' : 'show'
      this.$salesiq.floatwindow.visible(new_state)
    } catch(_error) {
      switch (this.stateValue) {
        case "loading":
          alert("Please try again in a few seconds, Zoho chat is still loading.")
          break
        case "failed":
          alert("Activation of live chat was blocked. Please check your browser/add-on settings.")
          break
        default:
          alert("Zoho chat is currently unavailable, sorry for the inconvenience.")
      }
    }
  }
}
