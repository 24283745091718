import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  /**
   *
   * @param {Event} ev
   */
  trigger(ev) {
    if (ev.target.tagName === "button") {
      ev.target.requestSubmit()
    } else {
      this.element.requestSubmit()
    }
  };
}
